<template>
  <v-container>
    <v-row>
      <v-spacer></v-spacer>
      <!-- MENUS -->
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <v-card height="70vh">
          <v-card-title>
            <div class="pa-7 position-relative">
              <h4 class="position-absolute" style="left: 0; top: 15px;">Menù</h4>
              <v-btn class="position-absolute" style="right: 0; top: 15px;" size="small" icon="mdi-plus" variant="tonal"
                color="primary" @click="showForm('Nuovo Menù', () => [''], menuFields, addMenu, hideForm)"></v-btn>
            </div>
          </v-card-title>

          <v-list class="scrollable-list" v-model:selected="selectedMenu" mandatory>
            <v-list-item v-for="(item, i) in items" :key="i" :value="item" color="primary">
              <template v-slot:append>
                <v-btn v-if="item.selected" variant="text" size="small" color="primary"
                  icon="mdi-radiobox-marked"></v-btn>
                <v-btn v-else variant="text" size="small" color="primary" icon="mdi-radiobox-blank"
                  @click="showForm(`Vuoi davvero impostare il menù ${item.name} come attivo ?`, () => [], [], selectMenu, hideForm, item.id)"></v-btn>
                <v-btn variant="text" size="small" class="mr-1" color="error" icon="mdi-delete"
                  @click="showForm(`Vuoi davvero eliminare il menù ${item.name} ?`, () => [], [], deleteMenu, hideForm, item.id)"></v-btn>
                <v-btn variant="text" size="small" color="primary" icon="mdi-pencil"
                  @click="showForm(`Modifica menù ${item.name}`, () => [item.name], menuFields, editMenu, hideForm, item.id)"></v-btn>
              </template>

              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <!-- SECTIONS -->
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <v-card height="70vh">
          <v-card-title>
            <div class="pa-7 position-relative">
              <h4 class="position-absolute" style="left: 0; top: 15px;">Sezioni</h4>
              <v-btn :disabled="selectedMenu.length <= 0 || selectedMenu[0] == undefined || selectedMenu[0].id == undefined" class="position-absolute" style="right: 0; top: 15px;" size="small" icon="mdi-plus" variant="tonal"
                color="primary"
                @click="showForm('Nuova Sezione', () => [''], sectionFields, addSection, hideForm, selectedMenu[0].id)"></v-btn>
            </div>
          </v-card-title>
          <v-list class="scrollable-list" v-model:selected="selectedSection" mandatory>

            <v-list-item v-for="(item, i) in currentMenuSections" :key="i" :value="item" color="primary">
              <template v-slot:append>
                <v-btn variant="text" size="small" class="mr-1" color="error" icon="mdi-delete"
                  @click="showForm(`Vuoi davvero eliminare la Sezione ${item.name} ?`, () => [], [], deleteSection, hideForm, item.id)"></v-btn>
                <v-btn variant="text" size="small" color="primary" icon="mdi-pencil"
                  @click="showForm(`Modifica Sezione ${item.name}`, () => [item.name], sectionFields, editSection, hideForm, item.id)"></v-btn>
              </template>

              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <!-- ELEMENTS -->
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <v-card height="70vh">
          <v-card-title>
            <div class="pa-7 position-relative">
              <h4 class="position-absolute" style="left: 0; top: 15px;">Elementi</h4>
              <v-btn :disabled="selectedSection.length <= 0 || selectedSection[0] == undefined ||  selectedSection[0].id == undefined" class="position-absolute" style="right: 0; top: 15px;" size="small" icon="mdi-plus" variant="tonal"
                color="primary"
                @click="showForm(`Nuovo Elemento`, () => ['', 0, ''], elementFields, addElement, hideForm, selectedSection[0].id)"></v-btn>
            </div>
          </v-card-title>
          <v-list class="scrollable-list">

            <v-list-item v-for="(item, i) in currentSectionElements" :key="i" color="primary">
              <template v-slot:append>
                <v-btn variant="text" size="small" class="mr-1" color="error" icon="mdi-delete"
                  @click="showForm(`Vuoi davvero eliminare l' elemento ${item.name} ?`, () => [], [], deleteElement, hideForm, item.id)"></v-btn>
                <v-btn variant="text" size="small" color="primary" icon="mdi-pencil"
                  @click="showForm(`Modifica Elemento ${item.name}`, () => [item.name, item.price, item.description], elementFields, editElement, hideForm, item.id)"></v-btn>
              </template>

              <v-list-item-title style="position: relative">{{ item.name }} <b
                  style="position: absolute; right: 10px; font-weight: 400;">{{ item.price }} €</b></v-list-item-title>
              <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>

      <!-- FORM -->
      <v-dialog v-model="form.show" persistent width="30%" min-width="300px">
        <v-card>
          <DataForm :title="form.title" :data="form.data" :fields="form.fields" :alert="form.alert"
            leftButtonLabel="Annulla" rightButtonLabel="Conferma" @click:right="form.confirm" @click:left="form.cancel">
          </DataForm>
        </v-card>
      </v-dialog>

    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn variant="outlined" color="primary" @click="logout">
          Logout
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';
import DataForm from "../components/DataForm.vue"

// Components
import AuthService from '../services/AuthService.js';
import MenuService from '../services/MenuService.js';
import SectionService from '../services/SectionService';
import ElementService from '../services/ElementService';
import { globalContainer } from '../store';
import router from "../router"

export default defineComponent({
  name: 'DashboardView',
  components: {
    DataForm,
  },
  data: () => ({
    user: {},
    items: [],
    selectedMenu: [],
    selectedSection: [],
    form: { show: false, title: "", data: [], fields: [], confirm: () => { }, cancel: () => { }, alert: "", id: undefined},
    menuFields: [{ type: 'text', label: 'Nome' }],
    sectionFields: [{ type: 'text', label: 'Nome' }],
    elementFields: [{ type: 'text', label: 'Nome' }, { type: 'number', label: 'Prezzo', min: 0, step: 0.5, prefix: '€' }, { type: 'text', label: 'Descrizione' },],
  }),
  methods: {
    loginFailed() {
      this.$cookies.remove('username');
      this.$cookies.remove('password');
      this.$cookies.remove('current_user');
      router.push("/login");
    },
    reloadItems(oldMenuId=undefined, oldSectionId=undefined) {
      MenuService.getMenus().then((data) => {
        this.items = data;
        if (this.items.length > 0 && this.selectedMenu[0] != undefined && this.selectedMenu.length <= 0) {
          this.selectedMenu[0] = this.items[0];
          if (this.selectedMenu[0].sections.length > 0 && this.selectedSection.length <= 0) {
            this.selectedSection[0] = this.selectedMenu[0].sections[0];
          }
          else if (this.selectedMenu[0].sections.length > 0 ){
            this.selectedSection[0] = this.selectedMenu[0].sections.find(sec => sec.id == oldSectionId || oldSectionId == undefined);
          }
        }
        else if (this.selectedMenu[0] != undefined){
          this.selectedMenu[0] = this.items.find(men => men.id == oldMenuId || oldMenuId == undefined);
          if (this.selectedMenu[0].sections.length > 0 && this.selectedSection.length <= 0) {
            this.selectedSection[0] = this.selectedMenu[0].sections[0];
          }
          else if (this.selectedMenu[0].sections.length > 0 ){
            this.selectedSection[0] = this.selectedMenu[0].sections.find(sec => sec.id == oldSectionId || oldSectionId == undefined);
          }
        }
      })
    },
    // FORM MANAGEMENT
    showForm(title, getData, fields, confirm, cancel, id=undefined) {
      this.form = { show: true, title: title, data: getData(), fields: fields, confirm: confirm, cancel: cancel, alert: "" , id: id};
    },
    hideForm() {
      this.form = { show: false, title: "", data: [], fields: [], confirm: () => { }, cancel: () => { }, alert: "" };
    },

    // FORM MENU
    addMenu(data) {
      MenuService.addMenu(data[0]).then(() => {
        this.hideForm();
        this.reloadItems();
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },
    editMenu(data) {
      MenuService.editMenu(this.form.id, data[0]).then(() => {
        this.hideForm();
        this.reloadItems();
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },
    selectMenu() {
      MenuService.enableMenu(this.form.id).then(() => {
        this.hideForm();
        globalContainer.Title = this.items.find((men) => men.id == this.selectedMenu[0].id).name;
        this.reloadItems();
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },
    deleteMenu() {
      MenuService.deleteMenu(this.form.id).then(() => {
        this.selectedMenu[0] = undefined;
        this.hideForm();
        this.reloadItems();
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },

    // FORM SECTION
    addSection(data) {
     SectionService.addSection(data[0], this.form.id).then(() => {
        this.hideForm();
        this.reloadItems(this.selectedMenu[0].id);
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },
    editSection(data) {
      SectionService.editSection(this.form.id, data[0]).then(() => {
        this.hideForm();
        this.reloadItems(this.selectedMenu[0].id);
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },
    deleteSection() {
      SectionService.deleteSection(this.form.id).then(() => {
        this.selectedSection[0] = undefined;
        this.hideForm();
        this.reloadItems();
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },

    // FORM ELEMENT
    addElement(data) {
      ElementService.addElement(data[0], data[1], data[2], this.form.id).then(() => {
        this.hideForm();
        this.reloadItems(this.selectedMenu[0].id, this.selectedSection[0].id);
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },
    editElement(data) {
      ElementService.editElement(this.form.id, data[0], data[1], data[2]).then(() => {
        this.hideForm();
        this.reloadItems(this.selectedMenu[0].id, this.selectedSection[0].id);
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },
    deleteElement() {
      ElementService.deleteElement(this.form.id).then(() => {
        this.hideForm();
        this.reloadItems(this.selectedMenu[0].id, this.selectedSection[0].id);
      }).catch((error) => {
        this.form.alert = error.response.data || "Errore, per altre informazioni contatta un amministratore";
      });
    },

    logout() {
      AuthService.logout().then(() => {
        this.$cookies.remove("username");
        this.$cookies.remove("password");
        this.$cookies.remove('current_user');
        router.go();
      });
    }
  },
  beforeMount() {
    AuthService.getUser()
      .then((data) => {
        globalContainer.User = data;
        this.user = data;
        this.reloadItems();
      })
      .catch(() => { //login failed / unauthorized
        if (this.$cookies.get("username") != undefined) {
          AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
            router.go();
          })
            .catch(() => {
              this.loginFailed();
            })
        }
        else {
          globalContainer.User = {};
          this.loginFailed();
        }
      });
  },
  computed: {
    currentMenuSections() {
      return this.selectedMenu.length && this.selectedMenu[0] != undefined > 0 ? this.selectedMenu[0].sections : [];
    },
    currentSectionElements() {
      return this.selectedSection.length && this.selectedSection[0] != undefined > 0 ? this.selectedSection[0].elements : [];
    },
  },
  watch: {
    selectedMenu() {
      if (this.selectedMenu.length > 0 && this.selectedMenu[0] != undefined && this.selectedMenu[0].sections.length > 0) {
        this.selectedSection[0] = this.selectedMenu[0].sections[0];
      }
    }
  }
});
</script>

<style scoped>
.scrollable-list {
  max-height: 60vh;
  overflow-y: auto;
}
</style>