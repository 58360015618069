import { createRouter, createWebHistory } from 'vue-router'
import MenuView from '../views/MenuView.vue'
import KartView from '../views/KartView.vue'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MenuView
  },
  {
    path: '/order',
    name: 'order',
    component: KartView
  },
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
