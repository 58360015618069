<template>
  <v-container>
    <v-row v-if="title != ''">
      <v-col>
        <h3 class="text-grey-darken-3"><b>{{ title }}</b></h3>
      </v-col>
    </v-row>
    <v-row v-if="error" >
      <v-col>
        <v-alert color="error" variant="outlined">
          {{ alert }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-for="(field, i) in fields" :key="i">
      <v-col cols="12">
        <div v-if="field.type === 'text'">
          <v-text-field variant="outlined" color="primary" v-model="dataClone[i]" :label="field.label"></v-text-field>
        </div>
        <div v-else-if="field.type === 'number'">
          <v-text-field v-model="dataClone[i]" :label="field.label" variant="outlined" color="primary"
            @input="sanitizeNumberInput(i, field.min, field.max)" :prefix="field.prefix || ''"
            :suffix="field.suffix || ''">
            <template v-slot:append-inner>
              <v-btn size="x-small" variant="tonal" class="mx-1" icon="mdi-minus"
                @click="decrement(i, field.step, field.min)"></v-btn>
              <v-btn size="x-small" variant="tonal" class="mx-1" icon="mdi-plus"
                @click="increment(i, field.step, field.max)"></v-btn>
            </template>
          </v-text-field>
        </div>
        <div v-else-if="field.type === 'bool'">
          <v-switch color="primary" v-model="dataClone[i]" :label="field.label"></v-switch>
        </div>
      </v-col>
    </v-row>
    <v-row class="text-right">
      <v-col>
        <v-btn class="mx-1" v-if="leftButtonLabel != ''" color="primary" variant="outlined" @click="leftClick">{{
          leftButtonLabel }}</v-btn>
        <v-btn class="mx-1" v-if="rightButtonLabel != ''" color="primary" @click="rightClick">{{
          rightButtonLabel
        }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'DataForm',
  props: {
    title: {
      type: String,
      default: () => ""
    },
    alert: {
      type: String,
      default: () => ""
    },
    data: { // ["this is some text for my field", 0, bool]
      type: Array,
      default: () => []
    },
    fields: {// [{type: 'text', label: 'placeholder'}, {type: 'number', label: 'number', prefix: '€', suffix: '$', step: 0.5, min: 0, max: 5}, {type: 'bool', label: 'boolean'}]
      type: Array,
      default: () => []
    },
    leftButtonLabel: {
      type: String,
      default: ""
    },
    rightButtonLabel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dataClone: [],
      error: false,
    };
  },
  beforeMount() {
    this.dataClone = JSON.parse(JSON.stringify(this.data));
    if (this.alert != '') {
      this.error = true;
      setTimeout(() => this.error = false, 7500)
    }
  },
  methods: {
    increment(element, step = 1, max = undefined) {
      this.dataClone[element] = parseFloat(this.dataClone[element] || 0) + step;
      if (max !== undefined) {
        this.dataClone[element] = Math.min(this.dataClone[element], max);
      }
    },
    decrement(element, step = 1, min = undefined) {
      this.dataClone[element] = parseFloat(this.dataClone[element] || 0) - step;
      if (min !== undefined) {
        this.dataClone[element] = Math.max(this.dataClone[element], min);
      }
    },
    sanitizeNumberInput(element, min = undefined, max = undefined) {
      this.dataClone[element] = this.dataClone[element].replace(/[^0-9.]/g, '');
      if ((this.dataClone[element].match(/\./g) || []).length > 1 ) this.dataClone[element] = this.dataClone[element].replace(/\.(?=[^.]*$)/, '');
      if(this.dataClone[element] === '' || this.dataClone[element] === '.' ) this.dataClone[element] = "0";
      let numericValue = Number(this.dataClone[element]);
      if (min !== undefined) {
        this.dataClone[element] = this.dataClone[element].endsWith('.') ? String(Math.max(numericValue, min)) + "." : Math.max(numericValue, min);
      }
      if (max !== undefined) {
        this.dataClone[element] = this.dataClone[element].endsWith('.') ? String(Math.min(numericValue, max)) + "." : Math.min(numericValue, max);
      }
    },
    rightClick() {
      this.$emit('click:right', this.dataClone);
    },
    leftClick() {
      this.$emit('click:left', this.dataClone);
    }
  },
  watch: {
    alert() {
      if (this.alert != '') {
        this.error = true;
        setTimeout(() => this.error = false, 7500)
      }
    }
  }
};
</script>
