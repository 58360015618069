import axios from 'axios';

class SectionService {

  static addSection(name, idMenu) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/section`, {name: name, idMenu: idMenu}, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static editSection(id, name) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(`${process.env.VUE_APP_BASE_URL}/section`, {name: name, id: id}, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static deleteSection(id) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.delete(`${process.env.VUE_APP_BASE_URL}/section?id=${id}`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default SectionService;
