<template>
  <v-card style=" border-radius: 0px;" variant="flat" width="100%" min-height="75vh">
    <v-card variant="flat" class="ma-0" :image="require('../assets/background.jpg')" height="15vh"
      style=" border-radius: 0px;">
      <v-card class="ma-0 pa-0" tile height="101%" variant="flat" color="transparent"
        style="background: linear-gradient(to bottom, transparent 55%, black 100%);">
        <v-card height="60%" variant="flat" color="transparent">
        </v-card>
        <v-card height="40%" variant="flat" color="transparent">
          <h1 style="width: fit-content; margin: auto; color: white;">Riepilogo</h1>
        </v-card>
      </v-card>
    </v-card>
    <v-card class="ma-5 rounded-xl " @click="routeTo(getMainPage)">
      <v-card color="primary" variant="tonal" class="pt-2">
        <v-row>
          <v-col class="text-center">
            <h1 style="padding-left: 6px; font-size: 18px;" :class="`font-weight-bold ml-2 `">Torna al Menu <v-icon
                class="ml-1" size="small" color="primary">mdi-silverware</v-icon></h1>
          </v-col>
        </v-row>
        <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis" style="padding-left: 6px"></h1>
      </v-card>
    </v-card>
    <ElementComponent v-for="(item, index) in items" :key="index" :item="item" class="mb-1" @itemclickadd="clickItem"
      @itemclickmore="clickItem" @itemclickless="clickItemRemove" />
    <v-card v-if="items.length != 0" class="ma-5 rounded-xl " @click="clearDialog = true">
      <v-card color="primary" variant="tonal" class="pt-2">
        <v-row>
          <v-col class="text-center">
            <h1 style="padding-left: 6px; font-size: 18px;" :class="`font-weight-bold ml-2 `">Svuota Lista <v-icon
                class="ml-1" size="small" color="primary">mdi-cart-off</v-icon></h1>
          </v-col>
        </v-row>
        <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis" style="padding-left: 6px"></h1>
      </v-card>
    </v-card>
    <v-card v-if="items.length != 0" class="ma-5 rounded-xl mb-12" elevation="8">
      <v-card color="primary" class="pt-2 mb-0">
        <v-row>
          <v-col cols="7">
            <h1 style="padding-left: 6px" :class="`text-h5 font-weight-bold ml-2`">Totale</h1>
          </v-col>
          <v-col cols="5">
            <h1 class="text-h5 font-weight-bold ml-6">€ {{ total }}</h1>
          </v-col>
        </v-row>
        <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis"
          :style="`padding-left: 6px; color: #dfe1e1 !important;`">Paga alla cassa mostrando questa lista</h1>
        <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis" style="padding-left: 6px"></h1>
      </v-card>
    </v-card>
    <v-row align="center">
      <v-spacer></v-spacer>
      <v-card v-if="items.length == 0" style="border-radius: 20px;" variant="tonal" class="text-center mt-4"
        align="center" width="85%">
        <v-card-text>
          <v-icon size="xx-large">mdi-cart-remove</v-icon>
          <h3>Il riepilogo è vuoto, consulta il nostro <a :style="`color: #${appColor}; text-decoration: none;`"
              href="/">menù</a> per aggiungere degli articoli</h3>
        </v-card-text>
      </v-card>
      <v-spacer></v-spacer>
    </v-row>
    <v-dialog max-width="500" v-model="clearDialog">
      <v-card title="Svuota Lista">
        <v-card-text>
          Vuoi davvero svuotare la lista?
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="clearDialog = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn variant="tonal" color="primary" @click="clearList();">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import MenuService from '@/services/MenuService';
import ElementComponent from '../components/ElementComponent'
import { globalContainer } from '../store';
import { defineComponent } from 'vue';
import router from "../router"

export default defineComponent({
  name: 'OrderView',
  components: {
    ElementComponent,
  },
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    updateList() {
      MenuService.getCurrentMenu().then((data) => {
        if (data != undefined && data != null && data != '') {
          let menu = data.sections.reduce((acc, section) => {
            section.elements.forEach((el) => acc.push(el));
            return acc;
          }, []);
          console.log(menu)
          let orders = this.getOrders();
          console.log(orders)
          this.items = orders;
          this.items = this.items.map((el) => {
            el.name = menu.find(elem => elem.id == el.id).name;
            el.price = menu.find(elem => elem.id == el.id).price;
            el.description = menu.find(elem => elem.id == el.id).description;
            return el;
          });
          let price = 0;
          this.items.forEach((el) => {
            price += (el.price * el.count)
          })
          this.total = price.toFixed(2).replace(".", ",");
        }
      })
    },
    clearList() {
      localStorage.order = "";
      this.items = 0;
      this.total = 0;
      window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
        detail: {
          storage: localStorage.getItem('order')
        }
      }));
      this.updateList();
      this.clearDialog = false;
    },
    clickItem(item) {
      if (localStorage.order == undefined || localStorage.order == "") {
        localStorage.order = item.id;
      } else {
        localStorage.order = localStorage.order + "+" + item.id;
      }
      window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
        detail: {
          storage: localStorage.getItem('order')
        }
      }));
      this.updateList();
    },
    clickItemRemove(item) {
      if (localStorage.order == undefined || localStorage.order == "") {
        localStorage.order = undefined;
      } else {
        let temp = localStorage.order.split("+");
        const index = temp.indexOf(String(item.id));
        if (index !== -1) {
          temp.splice(index, 1);
        }
        localStorage.order = temp.join("+");
      }
      window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
        detail: {
          storage: localStorage.getItem('order')
        }
      }));
      this.updateList();
    },
    getOrders() {
      if (localStorage.order == undefined || localStorage.order == "")
        return []
      let order = localStorage.order.split("+");
      const counts = order.reduce((acc, str) => {
        acc[str] = (acc[str] || 0) + 1;
        return acc;
      }, {});
      return Object.keys(counts).map(id => ({
        id: Number(id),
        count: counts[id]
      }));
    }
  },
  mounted() {
    this.updateList();
  },
  data: () => ({
    items: [],
    total: 0,
    clearDialog: false,
    appColor: process.env.VUE_APP_COLOR
  }),
  computed: {
    getMainPage() {
      return globalContainer.MainPage;
    }
  },
});
</script>
