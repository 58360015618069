<template>
    <v-card color="white" class="ma-5 rounded-xl" elevation="8">
      <v-card color="primary" dark class="pt-2">
        <v-row>
          <v-col cols="12">
            <h1 class="text-center text-h6 font-weight-bold">{{ item.name }}</h1>
          </v-col>
        </v-row>
         <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis" style="padding-left: 6px; color: #dfe1e1 !important;"></h1>
      </v-card>
    </v-card>
  </template>
  
  <script>
  export default {
    name: 'SectionComponent',
    props: {
      item: {
        type: Object,
        default: () => ({
          name: "",
        })
      }
    }
  }
  </script>
  