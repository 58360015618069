<template>
  <v-card variant="flat" class="ma-0" :image="require('../assets/background.jpg')" height="20vh"
    style=" border-radius: 0px;">
    <v-card class="ma-0 pa-0" tile height="101%" variant="flat" color="transparent"
      style="background: linear-gradient(to bottom, transparent 25%, black 100%);">
      <v-card height="40%" variant="flat" color="transparent"></v-card>
      <v-card height="60%" variant="flat" color="transparent">
        <h1 style="width: fit-content; margin: auto; color: white;">Menù</h1>
        <p class="text-center" style="width: fit-content; margin: auto; color: white;">Metti in lista ciò che vuoi
          ordinare e mostralo alla cassa</p>
      </v-card>
    </v-card>
  </v-card>
  <div v-for="(section, index) in menu.sections" :key="index">
    <SectionComponent :item="section"></SectionComponent>
    <ElementComponent v-for="(element, idx) in section.elements" :key="idx" :item="element" class="mb-1"
      @itemclickadd="clickItem" @itemclickmore="clickItem" @itemclickless="clickItemRemove"></ElementComponent>
  </div>
</template>

<script>
import { globalContainer } from '@/store';
import ElementComponent from '../components/ElementComponent'
import SectionComponent from '../components/SectionComponent'
import MenuService from '@/services/MenuService';

export default {
  name: 'MenuView',

  components: {
    ElementComponent,
    SectionComponent
  },
  methods: {
    updateList() {
      MenuService.getCurrentMenu().then((data) => {
        if (data != undefined && data != null && data != '') {
          this.menu = data;
          globalContainer.Title = this.menu.name;
          let orders = this.getOrders();
          this.menu.sections.map((section) => {
            section.elements.map((element) => {
              let order = orders.find(order => element.id == order.id);
              element.count = order != undefined ? order.count : undefined;
              return element;
            });
            return section;
          })
        }
      })
    },
    clickItem(item) {
      if (localStorage.order == undefined || localStorage.order == "") {
        localStorage.order = item.id;
      } else {
        localStorage.order = localStorage.order + "+" + item.id;
      }
      window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
        detail: {
          storage: localStorage.getItem('order')
        }
      }));
      this.updateList();
    },
    clickItemRemove(item) {
      if (localStorage.order == undefined || localStorage.order == "") {
        localStorage.order = undefined;
      } else {
        let temp = localStorage.order.split("+");
        const index = temp.indexOf(String(item.id));
        if (index !== -1) {
          temp.splice(index, 1);
        }
        localStorage.order = temp.join("+");
      }
      window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
        detail: {
          storage: localStorage.getItem('order')
        }
      }));
      this.updateList();
    },
    getOrders() {
      if (localStorage.order == undefined)
        return []
      let order = localStorage.order.split("+");
      const counts = order.reduce((acc, str) => {
        acc[str] = (acc[str] || 0) + 1;
        return acc;
      }, {});
      return Object.keys(counts).map(id => ({
        id: id,
        count: counts[id]
      }));
    }
  },
  mounted() {
    this.updateList();
  },
  data: () => ({
    items: [],
    menu: {}
  }),
}
</script>
