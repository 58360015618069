import axios from 'axios';

class ElementService {

  static addElement(name, price, description, idSection) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/element`, {name: name, idSection: idSection, price: price, description:description}, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static editElement(id, name, price, description,) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(`${process.env.VUE_APP_BASE_URL}/element`, {name: name, id: id, price: price, description:description}, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static deleteElement(id) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.delete(`${process.env.VUE_APP_BASE_URL}/element?id=${id}`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default ElementService;
