<template>
    <v-card color="white" class="ma-5 rounded-xl" elevation="8">
        <v-card color="white" class="pt-2">
            <v-row>
                <v-col cols="8">
                    <h1 style="padding-left: 6px" :class="`text-h6 font-weight-bold ml-2`">{{ item.name }}</h1>
                </v-col>
                <v-col cols="4">
                    <h1 class="text-h6 font-weight-bold ml-2">€ {{ item.price.toFixed(2).replace(".", ",") }}</h1>
                </v-col>
            </v-row>
            <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis"
                style="padding-left: 6px; color: #7b667b !important;">{{ item.description }}</h1>
            <v-row class="text-center ma-0 pa-0" v-if="kartEnabled == true">
                <v-col class=" ma-0 pa-0 pb-1">
                    <v-btn v-if="item.count == undefined" @click="$emit('itemclickadd', item)"
                        style="border-radius: 20px;" color="primary" variant="tonal"
                        append-icon="mdi-cart-plus">Aggiungi alla lista</v-btn>
                    <v-btn v-if="item.count != undefined" @click="$emit('itemclickless', item)"
                        style="border-radius: 0;border-top-left-radius: 20px;border-bottom-left-radius: 20px;"
                        color="primary" variant="tonal"><v-icon>mdi-minus</v-icon></v-btn>
                    <v-btn v-if="item.count != undefined" style="border-radius: 0;" color="primary"
                        variant="tonal">{{ item.count }}</v-btn>
                    <v-btn v-if="item.count != undefined" @click="$emit('itemclickmore', item)"
                        style="border-radius: 0;border-top-right-radius: 20px;border-bottom-right-radius: 20px;"
                        color="primary" variant="tonal"><v-icon>mdi-plus</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-card>
</template>

<script>
export default {
    name: 'ElementComponent',
    data: () => ({
        kartEnabled: Boolean(process.env.VUE_APP_KART)
    }),
    props: {
        item: {
            type: Object,
            default: () => ({
                id: undefined,
                name: "",
                description: "",
                price: "00,00",
                count: undefined,
            })
        }
    }
}
</script>